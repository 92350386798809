import React from "react"
import { Link, graphql } from "gatsby"

import { Container, Row, Col } from 'react-bootstrap';

import { ArrowRightShort } from 'react-bootstrap-icons';

import Image from '../components/image';
import Layout from "../components/layout"
import SEO from "../components/seo"

export default (props) => {

    const { currentPage, numPages } = props.pageContext

    return (
      <Layout>
        <SEO title="Мэдээ, үйл явдал" />
            <style>{`
            .blog-excerpt { 
                font-size: 20px;
                font-weight: 300;
            }
            .blog-image {
                border-radius: 2px;
            }
            `}</style>
        <div className="page-container page-container-mt">
            <Container>
                <Row>
                    <div className="col">
                        <h1 className="mb-5">Мэдээ, үйл явдал</h1>
                    </div>
                </Row>
                <Row className="mb-5">
                    {props.data.allMarkdownRemark.edges.map(({ node }, index) => {
                        return (
                            <Col md={4} key={index} className="mb-5">
                                <div className="mb-4">
                                    <Link to={node.fields.slug}>
                                        <Image className="blog-image" src={`events/${node.frontmatter.image}`} alt={node.frontmatter.title} />
                                    </Link>
                                </div>
                                <p>{node.frontmatter.date}</p>
                                <h3 className="mb-4">{node.frontmatter.title}</h3>
                                <p className="mb-4 blog-excerpt">{node.excerpt}</p>
                                <p className="mb-0">
                                    <Link to={node.fields.slug}>
                                        Дэлгэрэнгүй <ArrowRightShort />
                                    </Link>
                                </p>
                            </Col>
                        )
                    })}
                </Row>
                {numPages > 1 ? (
                    <Row>
                        <Col>
                            <nav aria-label="News navigation">
                            <ul className="pagination justify-content-center">
                                {Array.from({ length: numPages }, (_, i) => (
                                    <li key={i} className={`page-item ${i+1 === currentPage ? 'active' : ''}`}>
                                        <Link className="page-link" to={`/news/${i === 0 ? "" : i + 1}`}>
                                            {i + 1}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                            </nav>
                        </Col>
                    </Row>
                ): null}
            </Container>
        </div>
      </Layout>
    )
}

export const query = graphql`
query eventQuery($skip: Int, $limit: Int) {
  allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}, limit: $limit, skip: $skip) {
    edges {
      node {
        id
        frontmatter {
          title
          image
          date(formatString: "DD MMMM, YYYY")
        }
        fields {
          slug
        }
        excerpt
      }
    }
  }
}

`